import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyCxrjcPWo4YYHgmzhaxAd9a6BweqaORedw",
    authDomain: "react-journal-curso.firebaseapp.com",
    databaseURL: "https://react-journal-curso.firebaseio.com",
    projectId: "react-journal-curso",
    storageBucket: "react-journal-curso.appspot.com",
    messagingSenderId: "682373885650",
    appId: "1:682373885650:web:cfca87ce379f9630c8470b"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//base de datos
const db = firebase.firestore()

//auenticacion
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();


export {
    db,
    googleAuthProvider,
    firebase
}